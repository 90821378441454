import { useContext, useEffect, useState } from 'react';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    Menu,
    MenuItem,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LaptopIcon from '@mui/icons-material/Laptop';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import ClearAllIcon from '@mui/icons-material/ClearAll';

import api from '../../http';
import { AuthContext } from '../..';
import AdditionalInfoService from '../../services/additional-info-service';
import FormulaService from '../../services/formula-service';
import KosaService from '../../services/kosa-service';
import useErrorHandler from '../../services/error-handler';
import useMedia from '../../services/media-query';
import rangeToString from '../../services/range-string';
import {
    StyledTableCell,
    StyledDialogTitle,
    TextFieldWithState,
    FocusableTextField,
    StyledAutocomplete,
} from '../StyledComponents';
import { SnDialog } from '../KosaSns';

class KosaAdditionalInfoStore {
    types = [];
    caps = [];
    reels = [];

    constructor() {
        makeAutoObservable(this);
        this.load().catch((e) => console.warn(e));
    }

    async load() {
        await this.loadTypes();
        await this.loadCaps();
        await this.loadReels();
    }

    async loadTypes() {
        try {
            const answer = await AdditionalInfoService.kosaTypes();
            this.types = answer.data;
        } catch (e) {
            console.warn(e);
            throw new Error('Ошибка загрузки модификаций кос');
        }
    }

    async loadCaps() {
        try {
            const answer = await AdditionalInfoService.cap();
            this.caps = answer.data;
        } catch (e) {
            console.warn(e);
            throw new Error('Ошибка загрузки модификаций крышек');
        }
    }

    async loadReels() {
        try {
            const answer = await AdditionalInfoService.reel();
            this.reels = answer.data;
        } catch (e) {
            console.warn(e);
            throw new Error('Ошибка загрузки модификаций катушек');
        }
    }

    typeStr(typeId) {
        const type = this.types.find((type) => type.type_id === typeId);
        return type ? type.name : '';
    }

    capStr(capId) {
        const cap = this.caps.find((cap) => cap.cap_id === capId);
        return cap ? cap.name : '';
    }

    reelStr(reelId) {
        const reel = this.reels.find((reel) => reel.reel_id === reelId);
        return reel ? reel.name : '';
    }
}

const kAdditionInfoStore = new KosaAdditionalInfoStore();

//-------------------------------------------------------------------------------------------------
class KosaFormulaStore {
    loading = false; //Признак загрузки данных с сервера

    lengthError = true; //ошибка не соответствия расчетной длины с фактической
    was_marking_label_edit = false;

    item = null;
    marking_label = 'ТК1/0/0';
    type_id = -1;
    cap_type = 5;
    sensors_count = 0;
    lengths = [];
    tail_length = 100;
    total_length = 0;
    horizontal = false;
    text_formula = '';
    state_id = 1;

    reel = null;
    resistorOnTop = null;
    resistorOnBtn = null;
    dumbbell = true;
    bottomCap = 0;
    bottomTailLength = 0;

    quantity = 1; //Количество штук в позиции заказа
    sns = []; //Массив серийных номеров позиции заказа

    constructor() {
        makeAutoObservable(this);
    }

    clear() {
        this.lengthError = true;
        this.was_marking_label_edit = false;

        this.item = null;
        this.marking_label = 'ТК1/0/0';
        this.type_id = -1;
        this.cap_type = 5;
        this.sensors_count = 0;
        this.lengths = [];
        this.tail_length = 100;
        this.total_length = 0;
        this.horizontal = false;
        this.text_formula = '';
        this.state_id = 1;

        this.reel = null;
        this.resistorOnTop = null;
        this.resistorOnBtn = null;
        this.dumbbell = true;
        this.bottomCap = 0;
        this.bottomTailLength = 0;

        this.quantity = 1;
        this.sns = [];
    }

    /**
     * Вычисляет общую длину без первого датчика.
     *
     * @return {number} Общая длина без первого датчика.
     */
    fullLength() {
        let l = 0;
        for (let i = 1; i < this.lengths.length; i++) {
            l += this.lengths[i];
        }
        return l;
    }

    /**
     * Проверяет общую длину и отдельные длины, чтобы определить наличие ошибки.
     *
     * @return {boolean} Результат проверки длины.
     * @warning Имеет пассивный эффект выставления флага ошибки.
     */
    _checkLength() {
        if (this.total_length === 0 || this.lengths.length < 1) {
            this.lengthError = true;
            return false;
        }
        const sum = this.lengths.reduce((a, b) => a + b, 0);
        if (this.total_length === sum - this.lengths.at(0)) {
            this.lengthError = false;
            return true;
        } else {
            this.lengthError = true;
            return false;
        }
    }

    isGapChange(i) {
        if (i === 0) return false;
        if (i + 1 >= this.lengths.length) return false;
        return this.lengths[i] !== this.lengths[i + 1];
    }

    gapChangeList() {
        const result = [];
        for (let i = 0; i < this.lengths.length - 1; ++i) {
            if (this.isGapChange(i)) result.push(i);
        }
        return result;
    }

    setMarkingLabel(marking_label) {
        this.was_marking_label_edit = marking_label.length > 0;
        this.marking_label = marking_label;
    }

    setType(type_id) {
        this.type_id = type_id;
    }
    setCapType(cap_type) {
        this.cap_type = cap_type;
    }

    setSensorsCount(sensors_count) {
        if (sensors_count < this.lengths.length) {
            this.lengths = this.lengths.slice(0, sensors_count);
        } else {
            const lastLength = this.lengths.length > 0 ? this.lengths[this.lengths.length - 1] : 50;
            const arr = Array(sensors_count - this.lengths.length).fill(lastLength);
            this.lengths = this.lengths.concat(arr);
        }
        this.sensors_count = sensors_count;
        this._checkLength();
        this._generateMarkingIfNeed();
    }

    toGOST() {
        const needToAdd = (depth) => {
            if (depth < 500) return 50;
            else if (depth < 1000) return 100;
            else return 200;
        };

        if (this.total_length === 0 && this.sensors_count !== 0) {
            let depth = 0;
            const lengths = [0];
            for (let i = 1; i < this.sensors_count; ++i) {
                const add = needToAdd(depth);
                lengths.push(add);
                depth += add;
            }
            this.lengths = lengths;
            this.total_length = depth;
        } else if (this.sensors_count === 0 && this.total_length !== 0) {
            const lengths = [0];
            for (let depth = 0; depth < this.total_length; ) {
                let add = needToAdd(depth);
                if (this.total_length < depth + add) add = this.total_length - depth;
                lengths.push(add);
                depth += add;
            }
            this.lengths = lengths;
            this.sensors_count = lengths.length;
        } else {
            let depth = 0;
            const lengths = [0];
            for (let i = 1; i < this.lengths.length; i++) {
                const add = needToAdd(depth);
                lengths.push(add);
                depth += add;
            }
            this.lengths = lengths;
        }
        this._checkLength();
        this._generateMarkingIfNeed();
    }

    generateMarking() {
        const lbl = `ТК${this.tail_length / 100}/${this.total_length / 100}/${this.sensors_count}`;
        return lbl.replace(/\./g, ',');
    }

    _generateMarkingIfNeed() {
        if (!this.was_marking_label_edit) this.marking_label = this.generateMarking();
    }

    isMarkingCorrect() {
        if (this.marking_label.length === 0) return false;
        return this.marking_label === this.generateMarking();
    }

    setLength(index, length) {
        this.lengths[index] = Math.round(length);
        this._checkLength();
        this._generateMarkingIfNeed();
    }

    setTailLength(tail_length) {
        this.tail_length = Math.round(tail_length);
        this._checkLength();
        this._generateMarkingIfNeed();
    }

    setTotalLength(total_length) {
        this.total_length = Math.round(total_length);
        this._checkLength();
        this._generateMarkingIfNeed();
    }

    setHorizontal(horizontal) {
        this.horizontal = horizontal;
        this.bottomCap = 0;
        this.bottomTailLength = 0;
        this._checkLength();
    }

    setTextFormula(text_formula) {
        this.text_formula = text_formula;
    }

    setReel(reel_id) {
        this.reel = reel_id;
    }

    setResistorOnTop(nominal) {
        this.resistorOnTop = nominal;
    }

    setResistorOnBtn(nominal) {
        this.resistorOnBtn = nominal;
    }

    setDumbbell(dumbbell) {
        this.dumbbell = dumbbell;
    }

    setBottomCap(cap_id) {
        this.bottomCap = cap_id;
    }

    setBottomTailLength(length) {
        this.bottomTailLength = Math.round(length);
    }

    setQuantity(quantity) {
        this.quantity = quantity;
    }

    setSns(sns = []) {
        this.sns = sns;
    }

    _buildFormula() {
        let additional = {};
        if (this.reel !== null) additional.reel = this.reel;
        if (this.resistorOnTop !== null) additional.r_top = this.resistorOnTop;
        if (this.resistorOnBtn !== null) additional.r_btm = this.resistorOnBtn;
        if (!this.dumbbell) additional.dumbbell = this.dumbbell;
        if (this.bottomCap) additional.btm_cup = this.bottomCap;
        if (this.bottomTailLength) additional.btm_len = this.bottomTailLength;

        const formula = {
            device_type: 0,
            order_item: 'item_id' in this.item ? this.item.item_id : null,
            marking_label: this.marking_label,
            type_id: this.type_id === -1 ? null : this.type_id,
            cap_type: this.cap_type === 0 ? null : this.cap_type,
            sensors_count: this.sensors_count,
            lengths: this.lengths,
            tail_length: this.tail_length,
            total_length: this.total_length,
            horizontal: this.horizontal,
            text_formula: this.text_formula,
            additional: additional,
            quantity: this.quantity,
            sns: this.sns,
        };

        return formula;
    }

    _fromData(data) {
        this.was_marking_label_edit = data.marking_label.length > 0;

        this.marking_label = data.marking_label;
        this.type_id = data.type_id === null ? -1 : data.type_id;
        this.cap_type = data.cap_type === null ? 0 : data.cap_type;
        this.sensors_count = data.sensors_count;
        this.lengths = data.lengths;
        this.tail_length = data.tail_length;
        this.total_length = data.total_length;
        this.horizontal = data.horizontal;
        this.text_formula = data.text_formula;
        this.state_id = data.state_id;

        const add = data.additional;
        this.reel = 'reel' in add ? add.reel : null;
        this.resistorOnTop = 'r_top' in add ? add.r_top : null;
        this.resistorOnBtn = 'r_btm' in add ? add.r_btm : null;
        this.dumbbell = 'dumbbell' in add ? add.dumbbell : true;
        this.bottomCap = 'btm_cup' in add ? add.bottomCap : 0;
        this.bottomTailLength = 'btm_len' in add ? add.btm_len : 0;

        this.quantity = 'quantity' in data ? data.quantity : 1;
        this.sns = 'sns' in data ? data.sns : [];

        this._checkLength();
    }
    async _load(item_id) {
        try {
            const rs = await api.get('/formula/kosa/' + item_id);
            this._fromData(rs.data);
        } catch (e) {
            console.warn(e);
            throw new Error('Ошибка загрузки данных');
        }
    }

    async load(item) {
        this.clear();
        this.item = item;
        if (item.item_id) {
            try {
                this.loading = true;
                await this._load(item.item_id);
            } catch (e) {
                throw e;
            } finally {
                this.loading = false;
            }
        }
    }

    async copy(template_id) {
        try {
            const rs = await FormulaService.getKosaTemplate(template_id);
            this._fromData(rs.data);
        } catch (e) {
            console.warn(e);
            throw new Error('Ошибка загрузки шаблона');
        }
    }

    async copyDistance(template_id) {
        try {
            const rs = await FormulaService.getKosaTemplate(template_id);
            this.tail_length = rs.data.tail_length;
            const dataLength = rs.data.sensors_count;
            let depth = 0;
            for (let i = 0; i < this.sensors_count; ++i) {
                const index = i < dataLength ? i : dataLength - 1;
                this.lengths[i] = rs.data.lengths[index];
                depth += rs.data.lengths[index];
            }
            if (this.total_length === 0) {
                this.total_length = depth;
            }
            this._checkLength();
            this._generateMarkingIfNeed();
        } catch (e) {
            console.warn(e);
            throw new Error('Ошибка загрузки шаблона');
        }
    }

    async save() {
        try {
            const formula = this._buildFormula();
            if (formula.order_item) {
                //если пункт заказа уже существует то действуем по старинке (sns не обновляются!!!)
                await FormulaService.save(formula);
            } else {
                //иначе создается позиция заказа, формула для него и резервируются серийные номера
                await api.post('/order-items/add/kosa', {
                    order_id: this.item.order_id,
                    formula: formula,
                });
            }
        } catch (e) {
            console.warn(e);
            throw new Error('Ошибка сохранения записи');
        }
    }

    async saveAsTemplate() {
        try {
            const formula = this._buildFormula();
            await FormulaService.createKosaTemplate(formula);
        } catch (e) {
            console.warn(e);
            throw new Error('Ошибка сохранения шаблона');
        }
    }
}

const formulaStore = new KosaFormulaStore();

//-------------------------------------------------------------------------------------------------
class KosaFListStore {
    list = [];

    constructor() {
        makeAutoObservable(this);
    }

    async load() {
        try {
            const answer = await FormulaService.getKosaTemplates();
            this.list = answer.data;
        } catch (e) {
            console.warn(e);
            throw new Error('Ошибка загрузки данных');
        }
    }

    async removeTemplate(id) {
        try {
            await FormulaService.removeKosaTemplate(id);
            await this.load();
        } catch (e) {
            console.warn(e);
            throw new Error('Ошибка удаления шаблона');
        }
    }
}

const listStore = new KosaFListStore();

//=================================================================================================
//Диалог используется как для полного копирования косы из сохраненного шаблона, так и для копирования разметки косы
const CopyDialog = observer(({ open, onClose, onApply, accessHandler }) => {
    const [formula, setFormula] = useState(null);

    const errorHandler = useErrorHandler();
    const { isMobile } = useMedia();

    const loadItems = async () => {
        try {
            await listStore.load();
        } catch (e) {
            errorHandler(e);
        }
    };

    useEffect(() => {
        if (open) {
            loadItems();
        }
    }, [open]);

    const onDeleteClick = () => {
        try {
            listStore.removeTemplate(formula.template_id);
            accessHandler('Шаблон удален');
            setFormula(null);
        } catch (e) {
            errorHandler(e);
        }
    };
    //---------------------------------------------------------------------------------------------
    const renderTableRows = () => {
        let depth = 0;
        let rows = [];
        for (let i = 0; i < formula.lengths.length; ++i) {
            const length = formula.lengths[i] / 100;
            rows.push(
                <TableRow key={i}>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>{(depth += length)}</TableCell>
                    <TableCell>{length}</TableCell>
                </TableRow>
            );
        }
        return rows;
    };

    const renderTable = () => {
        const table = () => {
            return (
                <TableContainer sx={{ maxHeight: '50vh' }}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>№</StyledTableCell>
                                <StyledTableCell>Глубина</StyledTableCell>
                                <StyledTableCell>Расстояние</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        {formula ? (
                            <TableBody>
                                <TableRow>
                                    <TableCell>Х</TableCell>
                                    <TableCell>-</TableCell>
                                    <TableCell>{formula.tail_length / 100}</TableCell>
                                </TableRow>
                                {renderTableRows()}
                                {formula.horizontal ? (
                                    <TableRow>
                                        <TableCell>Н</TableCell>
                                        <TableCell>-</TableCell>
                                        <TableCell>{formula.additional.btm_len / 100}</TableCell>
                                    </TableRow>
                                ) : null}
                            </TableBody>
                        ) : null}
                    </Table>
                </TableContainer>
            );
        };
        return isMobile ? (
            table()
        ) : (
            <Paper sx={{ width: '100%', height: '50vh', overflow: 'hidden' }}>{table()}</Paper>
        );
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={isMobile ? false : 'sm'}
            fullScreen={isMobile}>
            <StyledDialogTitle>Копировать</StyledDialogTitle>
            <DialogContent>
                <DialogContentText>Копировать информацию из шаблона</DialogContentText>
                <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                    <Stack spacing={2}>
                        <StyledAutocomplete
                            value={formula}
                            onChange={(_, newValue) => setFormula(newValue)}
                            options={listStore.list}
                            getOptionLabel={(option) => option.marking_label}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Копировать из..."
                                    margin="normal"
                                    sx={{ minWidth: '15em' }}
                                />
                            )}
                        />
                        <Button
                            variant="contained"
                            color="error"
                            onClick={onDeleteClick}
                            disabled={!formula}
                            fullWidth>
                            Удалить шаблон
                        </Button>
                    </Stack>
                    <Stack spacing={2}>
                        <div>
                            Количество датчиков: {formula ? formula.sensors_count : 'Не выбрано'}
                        </div>
                        <div>Длина: {formula ? formula.total_length / 100 : 'Не выбрано'} м.</div>
                        {renderTable()}
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => onApply(formula)}
                    disabled={formula === null}>
                    Применить
                </Button>
                <Button variant="outlined" onClick={onClose}>
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
});

//-------------------------------------------------------------------------------------------------
const SaveDialog = ({ open, onClose, accessHandler }) => {
    const errorHandler = useErrorHandler();

    const onApplyClick = async () => {
        try {
            await formulaStore.saveAsTemplate();
            accessHandler('Шаблон сохранен');
            onClose();
        } catch (e) {
            errorHandler(e);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <StyledDialogTitle>Сохранение шаблона</StyledDialogTitle>
            <DialogContent>
                Вы действительно хотите сохранить текущую конфигурацию косы как шаблон?
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onApplyClick}>
                    Сохранить
                </Button>
                <Button variant="outlined" onClick={onClose}>
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
};

//=================================================================================================
const LoadingStub = ({ item, onClose }) => {
    return (
        <Dialog open={item} onClose={onClose} disableRestoreFocus fullWidth maxWidth="md">
            <StyledDialogTitle>
                {'item_id' in item ? 'Редактирование позиции заказа' : 'Добавление позиции заказа'}
            </StyledDialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h1">Загрузка...</Typography>
            </DialogContent>
        </Dialog>
    );
};
//=================================================================================================
const ViewTypeButton = ({ mobile, onClick }) => {
    return (
        <IconButton onClick={onClick}>
            {mobile ? <PhoneIphoneIcon color="info" /> : <LaptopIcon color="info" />}
        </IconButton>
    );
};

//=================================================================================================
/**
 * Отображение для пользователей группы "Администратор"
 */
const AKosa = observer(({ item, onClose }) => {
    const { authStore } = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const { isMobile } = useMedia();

    const [showCopyDialog, setShowCopyDialog] = useState(false); //отобразить диалог копирования
    const [showDistanceDialog, setShowDistanceDialog] = useState(false); //отобразить диалог копирования расстояний
    const [showSaveDialog, setShowSaveDialog] = useState(false); //отобразить диалог сохранения
    const [addAdditionInfoMenuAnchor, setAddAdditionInfoMenuAnchor] = useState(null); //меню добавления дополнительной информации
    const [mobileView, setMobileView] = useState(false); //вид для смартфона
    const [snsDialog, setSnsDialog] = useState(false); //отобразить диалог присвоения серийных номеров

    const pushError = useErrorHandler();

    useEffect(() => {
        setMobileView(isMobile);
    }, []);

    const pushAccess = (accessText) => {
        enqueueSnackbar(accessText, { variant: 'success' });
    };

    const onApplyClick = async () => {
        try {
            await formulaStore.save();
            onClose();
        } catch (e) {
            pushError(e);
        }
    };

    const snsDialogOnApplyClick = async (sns) => {
        try {
            if (formulaStore.item.item_id) {
                //если мы редактируем существующую позицию заказа
                const orderItem = { ...formulaStore.item, sns: sns };
                await KosaService.save(orderItem);
            }
            formulaStore.setSns(sns);
            setSnsDialog(false);
        } catch (e) {
            pushError(e);
        }
    };

    const autoAssignClick = async () => {
        const save = async (sns) => {
            try {
                if (formulaStore.item.item_id) {
                    //если мы редактируем существующую позицию заказа
                    const orderItem = { ...formulaStore.item, sns: sns };
                    await KosaService.save(orderItem);
                }
            } catch (e) {
                throw new Error('Не удалось автоматически назначить серийные номера');
            }
        };

        try {
            if (formulaStore.sns.length === 0) {
                const response = await KosaService.getNextId();
                let nextSns = +response.data;
                const nSns = [];
                for (let i = 0; i < formulaStore.quantity; i++) {
                    nSns.push(nextSns++);
                }
                await save(nSns);
                formulaStore.setSns(nSns);
            } else if (formulaStore.sns.length < formulaStore.quantity) {
                const nSns = [formulaStore.sns.at(-1) + 1];
                while (nSns.length < formulaStore.quantity - formulaStore.sns.length) {
                    nSns.push(nSns.at(-1) + 1);
                }
                if (formulaStore.item.item_id) {
                    //если мы редактируем существующую позицию заказа
                    const sns = [...formulaStore.sns, ...nSns];
                    await save(sns);
                    formulaStore.setSns(sns);
                } else {
                    const response = await KosaService.checkSns(nSns);
                    if (response.data === true)
                        throw new Error('Не удалось автоматически назначить серийные номера');
                    else formulaStore.setSns([...formulaStore.sns, ...nSns]);
                }
            } else if (formulaStore.sns.length > formulaStore.quantity) {
                const nSns = formulaStore.sns.slice(0, formulaStore.quantity);
                await save(nSns);
                formulaStore.setSns(nSns);
            }
        } catch (e) {
            pushError(e);
        }
    };

    const onSensorsCountChangeBehavior = (event) => {
        let val = +event.target.value;
        if (val < 0) val = 0;
        else if (val > 250) val = 250;
        formulaStore.setSensorsCount(val);
    };

    const fillBottom = (index) => {
        const l = formulaStore.lengths.at(index);
        for (let i = index + 1; i < formulaStore.lengths.length; i++) {
            formulaStore.setLength(i, l);
        }
    };

    const onCopyFromTemplate = async (formula) => {
        try {
            await formulaStore.copy(formula.template_id);
            setShowCopyDialog(false);
        } catch (e) {
            pushError(e);
        }
    };

    const onApplyDistance = async (formula) => {
        try {
            await formulaStore.copyDistance(formula.template_id);
            setShowDistanceDialog(false);
        } catch (e) {
            pushError(e);
        }
    };

    const isAdmin = authStore.user.role === 2;
    const isNotEditable = !isAdmin || formulaStore.state_id > 1;

    //---------------------------------------------------------------------------------------------
    const renderTypeMenu = () => {
        const types = kAdditionInfoStore.types;
        const menu = types.map((type) => (
            <MenuItem key={type.type_id} value={type.type_id}>
                {type.name}
            </MenuItem>
        ));
        menu.unshift(
            <MenuItem value={-1} key={-1}>
                Без разницы
            </MenuItem>
        );
        return menu;
    };

    const renderCapMenu = () => {
        const caps = kAdditionInfoStore.caps;
        const menu = caps.map((cap) => (
            <MenuItem value={cap.cap_id} key={cap.cap_id}>
                {cap.name}
            </MenuItem>
        ));
        menu.unshift(
            <MenuItem value={0} key={0}>
                Без крышки
            </MenuItem>
        );
        return menu;
    };

    const renderReelMenu = () => {
        const reels = kAdditionInfoStore.reels;
        const menu = reels.map((reel) => (
            <MenuItem value={reel.reel_id} key={reel.reel_id}>
                {reel.name}
            </MenuItem>
        ));
        menu.unshift(
            <MenuItem value={0} key={0}>
                Без катушки
            </MenuItem>
        );
        return menu;
    };

    const renderLengthM = () => {
        const tail = () => {
            return (
                <FocusableTextField
                    label="Хвост м."
                    value={formulaStore.tail_length / 100}
                    onChange={(e) => formulaStore.setTailLength(+e.target.value * 100)}
                    disabled={isNotEditable}
                    error={formulaStore.tail_length === 0}
                    InputProps={{
                        inputProps: { min: 0, max: 1000, type: 'number', step: 0.5 },
                    }}
                    size="small"
                    margin="normal"
                    sx={{ minWidth: '8em' }}
                />
            );
        };
        //-----------------------------------------------------------------------------------------
        const length = () => {
            return (
                <FocusableTextField
                    label="Длина м."
                    value={formulaStore.total_length / 100}
                    onChange={(e) => formulaStore.setTotalLength(+e.target.value * 100)}
                    error={formulaStore.lengthError}
                    disabled={isNotEditable}
                    InputProps={{
                        inputProps: { min: 0, max: 1000, type: 'number', step: 0.5 },
                    }}
                    size="small"
                    margin="normal"
                    sx={{ minWidth: '8em' }}
                />
            );
        };
        //-----------------------------------------------------------------------------------------
        const sensors = () => {
            return (
                <FocusableTextField
                    label="Количество датчиков"
                    value={formulaStore.sensors_count}
                    onChange={onSensorsCountChangeBehavior}
                    disabled={isNotEditable}
                    error={formulaStore.sensors_count === 0}
                    InputProps={{
                        inputProps: { min: 0, max: 250, type: 'number' },
                    }}
                    size="small"
                    margin="normal"
                    sx={{ minWidth: '10em' }}
                />
            );
        };
        //-----------------------------------------------------------------------------------------
        const toGost = () => {
            return (
                <>
                    <Tooltip title="Заполнить из шаблона">
                        <IconButton
                            color="success"
                            onClick={() => setShowDistanceDialog(true)}
                            disabled={isNotEditable}>
                            <DownloadIcon sx={{ marginTop: '5px' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Заполнить по ГОСТу">
                        <IconButton
                            color="success"
                            onClick={() => formulaStore.toGOST()}
                            disabled={isNotEditable}>
                            <BeenhereIcon sx={{ marginTop: '5px' }} />
                        </IconButton>
                    </Tooltip>
                </>
            );
        };
        //-----------------------------------------------------------------------------------------
        return mobileView ? (
            <Stack>
                <div>{tail()}</div>
                <Stack direction="row" spacing={1}>
                    <div>{length()}</div>
                    <div>{sensors()}</div>
                    {toGost()}
                </Stack>
            </Stack>
        ) : (
            <Stack direction="row" spacing={2}>
                <div>{tail()}</div>
                <div>{length()}</div>
                <div>{sensors()}</div>
                {toGost()}
            </Stack>
        );
    };

    const renderSns = () => {
        const isRight = formulaStore.quantity === formulaStore.sns.length;
        const str = formulaStore.sns.length === 0 ? 'Не выбрано' : rangeToString(formulaStore.sns);
        return (
            <Stack direction="row" alignItems="center" justifyContent="flex-end">
                <Tooltip title="Присвоенные серийные номера">
                    <Typography sx={isRight ? null : { color: 'red' }} noWrap>
                        {isRight ? null : '<!> '}
                        {str}
                    </Typography>
                </Tooltip>
                <Tooltip title="Автоматическое присвоение серийных номеров">
                    <IconButton
                        color={isRight ? 'error' : 'info'}
                        disabled={isNotEditable}
                        onClick={autoAssignClick}>
                        <ClearAllIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Редактировать серийные номера">
                    <IconButton
                        color={isRight ? 'success' : 'error'}
                        disabled={isNotEditable}
                        onClick={() => setSnsDialog(true)}>
                        <ViewCompactIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        );
    };

    const renderHorizontalBlock = () => {
        const checkbox = () => {
            return (
                <FormControlLabel
                    label="Горизонтальная"
                    control={
                        <Checkbox
                            checked={formulaStore.horizontal}
                            onChange={(e) => formulaStore.setHorizontal(e.target.checked)}
                        />
                    }
                    disabled={isNotEditable}
                />
            );
        };
        //-----------------------------------------------------------------------------------------
        const cap = () => {
            return (
                <FormControl margin="normal" size="small" fullWidth>
                    <InputLabel id="cap2-select">Нижняя крышка</InputLabel>
                    <Select
                        value={formulaStore.bottomCap}
                        onChange={(e) => formulaStore.setBottomCap(+e.target.value)}
                        label="Нижняя крышка"
                        labelId="cap2-select"
                        variant="outlined"
                        disabled={isNotEditable}>
                        {renderCapMenu()}
                    </Select>
                </FormControl>
            );
        };
        //-----------------------------------------------------------------------------------------
        const tail = () => {
            return (
                <FocusableTextField
                    label="Нижний хвост м."
                    value={formulaStore.bottomTailLength / 100}
                    onChange={(e) => formulaStore.setBottomTailLength(+e.target.value * 100)}
                    disabled={isNotEditable}
                    error={formulaStore.bottomTailLength === 0}
                    InputProps={{
                        inputProps: { min: 0, max: 1000, type: 'number', step: 0.5 },
                    }}
                    size="small"
                    margin="none"
                    sx={{ minWidth: '8em' }}
                />
            );
        };
        //-----------------------------------------------------------------------------------------
        return mobileView ? (
            <Stack sx={{ paddingBottom: '0.5em' }}>
                {checkbox()}
                {formulaStore.horizontal ? (
                    <Stack direction="row" spacing={1}>
                        {cap()}
                        {tail()}
                    </Stack>
                ) : null}
            </Stack>
        ) : (
            <Stack direction="row" spacing={2}>
                {checkbox()}
                {formulaStore.horizontal ? (
                    <>
                        {cap()}
                        {tail()}
                    </>
                ) : null}
            </Stack>
        );
    };

    const renderTableBody = () => {
        const style = (i) => {
            return formulaStore.isGapChange(i) ? { backgroundColor: '#A5DFA8' } : {};
        };
        const lengths = formulaStore.lengths;
        let depth = 0;
        let rows = [];
        for (let i = 0; i < lengths.length; i++) {
            rows.push(
                <TableRow key={i} hover sx={style(i)}>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>{(depth += lengths[i] / 100)}</TableCell>
                    <TableCell>
                        <FocusableTextField
                            value={lengths.at(i) / 100}
                            onChange={(e) => formulaStore.setLength(i, +e.target.value * 100)}
                            disabled={isNotEditable}
                            InputProps={{
                                inputProps: { min: 0, max: 1000, type: 'number', step: 0.5 },
                            }}
                            onWheel={(e) => e.target.blur()}
                            size="small"
                            margin="none"
                            sx={{ minWidth: '5em' }}
                        />
                    </TableCell>
                    <TableCell>
                        <Tooltip title="Заполнить вниз">
                            <IconButton
                                color="success"
                                onClick={() => fillBottom(i)}
                                disabled={isNotEditable}>
                                <DownloadIcon sx={{ marginTop: '5px' }} />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            );
        }
        return rows;
    };

    const renderTable = () => {
        const table = () => {
            return (
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>№</StyledTableCell>
                            <StyledTableCell>Глубина</StyledTableCell>
                            <StyledTableCell>Расстояние м.</StyledTableCell>
                            <StyledTableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow hover>
                            <TableCell>X</TableCell>
                            <TableCell>-</TableCell>
                            <TableCell>
                                <FocusableTextField
                                    value={formulaStore.tail_length / 100}
                                    onChange={(e) =>
                                        formulaStore.setTailLength(+e.target.value * 100)
                                    }
                                    disabled={isNotEditable}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 1000,
                                            type: 'number',
                                            step: 0.5,
                                        },
                                    }}
                                    onWheel={(e) => e.target.blur()}
                                    size="small"
                                    margin="none"
                                    sx={{ minWidth: '5em' }}
                                />
                            </TableCell>
                        </TableRow>
                        {renderTableBody()}
                        {formulaStore.horizontal ? (
                            <TableRow hover>
                                <TableCell>Н</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                    <FocusableTextField
                                        value={formulaStore.bottomTailLength / 100}
                                        onChange={(e) =>
                                            formulaStore.setBottomTailLength(+e.target.value * 100)
                                        }
                                        disabled={isNotEditable}
                                        InputProps={{
                                            inputProps: {
                                                min: 0,
                                                max: 1000,
                                                type: 'number',
                                                step: 0.5,
                                            },
                                        }}
                                        size="small"
                                        margin="none"
                                        onWheel={(e) => e.target.blur()}
                                        sx={{ minWidth: '5em' }}
                                    />
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        ) : null}
                    </TableBody>
                </Table>
            );
        };
        return mobileView ? (
            table()
        ) : (
            <Paper sx={{ width: '100%', height: '60vh', overflow: 'hiden' }}>
                <TableContainer sx={{ maxHeight: '60vh' }}>{table()}</TableContainer>
            </Paper>
        );
    };

    if (formulaStore.loading === true) {
        return <LoadingStub item={item} onClose={onClose} />;
    }

    //---------------------------------------------------------------------------------------------
    return (
        <>
            <Dialog
                open={item}
                onClose={onClose}
                disableRestoreFocus
                fullWidth
                maxWidth={mobileView ? false : 'md'}
                fullScreen={mobileView}>
                <StyledDialogTitle>
                    {item.item_id ? 'Редактирование позиции заказа' : 'Добавление позиции заказа'}
                </StyledDialogTitle>
                <DialogContent>
                    <Stack direction={mobileView ? 'column' : 'row'} spacing={2}>
                        <Stack direction="column" spacing={1}>
                            <Stack direction="row" spacing={1} justifyContent="space-between">
                                <div style={{ width: '100%' }}>
                                    <FocusableTextField
                                        label="Маркировка"
                                        value={formulaStore.marking_label}
                                        onChange={(e) =>
                                            formulaStore.setMarkingLabel(e.target.value)
                                        }
                                        disabled={isNotEditable}
                                        error={!formulaStore.isMarkingCorrect()}
                                        size="small"
                                        margin="normal"
                                        fullWidth
                                    />
                                </div>
                                {isNotEditable ? null : (
                                    <>
                                        <Tooltip title="Копировать из шаблона">
                                            <IconButton
                                                color="success"
                                                onClick={() => setShowCopyDialog(true)}
                                                disabled={isNotEditable}>
                                                <DownloadIcon sx={{ marginTop: '5px' }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                            title="Сохранить как шаблон"
                                            onClick={() => setShowSaveDialog(true)}>
                                            <IconButton color="success">
                                                <SaveIcon sx={{ marginTop: '5px' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                )}
                            </Stack>

                            {renderLengthM()}

                            <Stack direction="row" spacing={1} justifyContent="space-between">
                                <div>
                                    <TextFieldWithState
                                        label="Количество"
                                        size="small"
                                        margin="normal"
                                        fullWidth
                                        disabled={isNotEditable}
                                        value={formulaStore.quantity}
                                        error={formulaStore.quantity < 1}
                                        onChange={(e) => formulaStore.setQuantity(+e.target.value)}
                                        InputProps={{
                                            inputProps: { min: 1, max: 10000, type: 'number' },
                                        }}
                                    />
                                </div>
                                {renderSns()}
                            </Stack>
                            <FormControl fullWidth margin="normal" size="small">
                                <InputLabel id="type-select">Тип косы</InputLabel>
                                <Select
                                    value={formulaStore.type_id}
                                    onChange={(e) => formulaStore.setType(+e.target.value)}
                                    label="Тип косы"
                                    labelId="type-select"
                                    variant="outlined"
                                    disabled={isNotEditable}>
                                    {renderTypeMenu()}
                                </Select>
                            </FormControl>

                            {renderHorizontalBlock()}

                            <Stack direction="row" spacing={2}>
                                <FormControlLabel
                                    label="Грузик"
                                    control={
                                        <Checkbox
                                            checked={formulaStore.dumbbell}
                                            onChange={(e) =>
                                                formulaStore.setDumbbell(e.target.checked)
                                            }
                                        />
                                    }
                                    disabled={isNotEditable}
                                />
                                <FormControl margin="normal" size="small" fullWidth>
                                    <InputLabel id="cap-select">Крышка</InputLabel>
                                    <Select
                                        value={formulaStore.cap_type}
                                        onChange={(e) => formulaStore.setCapType(+e.target.value)}
                                        label="Крышка"
                                        labelId="cap-select"
                                        variant="outlined"
                                        disabled={isNotEditable}>
                                        {renderCapMenu()}
                                    </Select>
                                </FormControl>
                            </Stack>

                            <FocusableTextField
                                label="Описание"
                                multiline
                                fullWidth
                                value={formulaStore.text_formula}
                                onChange={(e) => formulaStore.setTextFormula(e.target.value)}
                                disabled={isNotEditable}
                                size="small"
                                margin="normal"
                            />

                            <Button
                                color="success"
                                onClick={(e) => setAddAdditionInfoMenuAnchor(e.currentTarget)}
                                variant="contained"
                                sx={{ maxWidth: '10em' }}
                                disabled={isNotEditable}>
                                Добавить
                                <ArrowDropDownIcon />
                            </Button>
                            <Menu
                                anchorEl={addAdditionInfoMenuAnchor}
                                open={Boolean(addAdditionInfoMenuAnchor)}
                                onClose={() => setAddAdditionInfoMenuAnchor(null)}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                                <MenuItem
                                    disabled={isNotEditable || formulaStore.resistorOnTop !== null}
                                    onClick={() => {
                                        formulaStore.setResistorOnTop('');
                                        setAddAdditionInfoMenuAnchor(null);
                                    }}>
                                    Верхний резистор
                                </MenuItem>
                                <MenuItem
                                    disabled={isNotEditable || formulaStore.resistorOnBtn !== null}
                                    onClick={() => {
                                        formulaStore.setResistorOnBtn('');
                                        setAddAdditionInfoMenuAnchor(null);
                                    }}>
                                    Нижний резистор
                                </MenuItem>
                                <MenuItem
                                    disabled={isNotEditable || formulaStore.reel !== null}
                                    onClick={() => {
                                        formulaStore.setReel(0);
                                        setAddAdditionInfoMenuAnchor(null);
                                    }}>
                                    Катушка
                                </MenuItem>
                            </Menu>
                            <div style={{ height: '0.2em' }} />

                            <Stack direction="row" spacing={2}>
                                {formulaStore.resistorOnTop !== null ? (
                                    <Stack direction="row" spacing={0.5}>
                                        <FocusableTextField
                                            label="Верхний резистор"
                                            placeholder="Нет"
                                            value={formulaStore.resistorOnTop}
                                            onChange={(e) =>
                                                formulaStore.setResistorOnTop(e.target.value)
                                            }
                                            disabled={isNotEditable}
                                            size="small"
                                            margin="normal"
                                            sx={{ minWidth: '8em' }}
                                        />
                                        <Tooltip title="Удалить верхний резистор">
                                            <IconButton
                                                color="error"
                                                onClick={() => formulaStore.setResistorOnTop(null)}
                                                disabled={isNotEditable}
                                                sx={{ paddingTop: '0.5em' }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                ) : null}
                                {formulaStore.resistorOnBtn !== null ? (
                                    <Stack direction="row" spacing={0.5}>
                                        <FocusableTextField
                                            label="Нижний резистор"
                                            placeholder="Нет"
                                            value={formulaStore.resistorOnBtn}
                                            onChange={(e) =>
                                                formulaStore.setResistorOnBtn(e.target.value)
                                            }
                                            disabled={isNotEditable}
                                            size="small"
                                            margin="normal"
                                            sx={{ minWidth: '8em' }}
                                        />
                                        <Tooltip title="Удалить нижний резистор">
                                            <IconButton
                                                color="error"
                                                onClick={() => formulaStore.setResistorOnBtn(null)}
                                                disabled={isNotEditable}
                                                sx={{ paddingTop: '0.5em' }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                ) : null}
                            </Stack>

                            {formulaStore.reel !== null ? (
                                <Stack direction="row" spacing={0.5}>
                                    <FormControl margin="normal" size="small" fullWidth>
                                        <InputLabel id="reel-select">Катушка</InputLabel>
                                        <Select
                                            value={formulaStore.reel}
                                            onChange={(e) => formulaStore.setReel(+e.target.value)}
                                            label="Катушка"
                                            labelId="reel-select"
                                            variant="outlined"
                                            disabled={isNotEditable}>
                                            {renderReelMenu()}
                                        </Select>
                                    </FormControl>
                                    <Tooltip title="Удалить катушку">
                                        <IconButton
                                            color="error"
                                            onClick={() => formulaStore.setReel(null)}
                                            disabled={isNotEditable}
                                            sx={{ paddingTop: '0.5em' }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            ) : null}
                        </Stack>

                        <Stack spacing={1}>
                            <Stack
                                direction="row"
                                spacing={2}
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{ width: 'max-content' }}>
                                <Typography>
                                    Рабочая длина: {formulaStore.fullLength() / 100} м
                                </Typography>
                                <Typography>
                                    Полная длина:{' '}
                                    {(formulaStore.fullLength() +
                                        formulaStore.tail_length +
                                        formulaStore.bottomTailLength) /
                                        100}{' '}
                                    м
                                </Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between">
                                <Typography>
                                    Marked:{' '}
                                    {formulaStore
                                        .gapChangeList()
                                        .map((i) => i + 1)
                                        .join(', ')}
                                </Typography>
                                <ViewTypeButton
                                    mobile={mobileView}
                                    onClick={() => setMobileView(!mobileView)}
                                />
                            </Stack>
                            {renderTable()}
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    {isAdmin ? (
                        <Button
                            onClick={onApplyClick}
                            disabled={isNotEditable}
                            color="primary"
                            variant="contained">
                            Сохранить
                        </Button>
                    ) : null}
                    <Button onClick={onClose} variant="outlined">
                        Отмена
                    </Button>
                </DialogActions>
            </Dialog>
            <CopyDialog
                open={showCopyDialog}
                onClose={() => setShowCopyDialog(false)}
                onApply={onCopyFromTemplate}
                accessHandler={pushAccess}
            />
            <CopyDialog
                open={showDistanceDialog}
                onClose={() => setShowDistanceDialog(false)}
                onApply={onApplyDistance}
                accessHandler={pushAccess}
            />
            <SaveDialog
                open={showSaveDialog}
                onClose={() => setShowSaveDialog(false)}
                accessHandler={pushAccess}
            />
            <SnDialog
                open={snsDialog}
                sns={formulaStore.sns}
                quantity={formulaStore.quantity}
                onClose={() => setSnsDialog(false)}
                onApply={snsDialogOnApplyClick}
            />
        </>
    );
});

//=================================================================================================
/**
 * Отображение для пользователей группы "Пользователи"
 */
const UKosa = observer(({ item, onClose }) => {
    const { isMobile } = useMedia();

    const [mobileView, setMobileView] = useState(false); //вид для смартфона

    useEffect(() => {
        setMobileView(isMobile);
    }, []);

    const renderSns = () => {
        const sns = formulaStore.sns.length === 0 ? 'Не выбрано' : rangeToString(formulaStore.sns);
        return (
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography>
                    s/n: <b>{sns}</b>
                </Typography>
                <Typography>
                    кол: <b>{formulaStore.quantity}</b>
                </Typography>
            </Stack>
        );
    };

    const renderMarked = () => {
        return (
            <Typography>
                Помечать:{' '}
                <b>
                    {formulaStore
                        .gapChangeList()
                        .map((i) => i + 1)
                        .join(', ')}
                </b>
            </Typography>
        );
    };

    const renderLengthM = () => {
        const tail = () => {
            return (
                <Typography>
                    Хвост: <b>{formulaStore.tail_length / 100}</b> м.
                </Typography>
            );
        };
        const length = () => {
            const l =
                (formulaStore.fullLength() +
                    formulaStore.tail_length +
                    formulaStore.bottomTailLength) /
                100;
            return (
                <Typography>
                    Длина: <b>{l}</b> м.
                </Typography>
            );
        };
        const sensors = () => {
            return (
                <Typography>
                    Датчиков: <b>{formulaStore.sensors_count}</b> шт.
                </Typography>
            );
        };

        return mobileView ? (
            <Stack>
                <Stack direction="row" spacing={2}>
                    {length()}
                    {sensors()}
                </Stack>
                {renderMarked()}
                {tail()}
            </Stack>
        ) : (
            <Stack>
                <Stack direction="row" spacing={2}>
                    <div>{length()}</div>
                    <div>{sensors()}</div>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <div>{renderMarked()}</div>
                    <div>{tail()}</div>
                </Stack>
            </Stack>
        );
    };

    const renderHorizontalBlock = () => {
        if (!formulaStore.horizontal) return null;
        const rHorizontal = () => {
            return (
                <Typography>
                    <b>Горизонтальная</b>
                </Typography>
            );
        };
        const cap = () => {
            return (
                <Typography>
                    Нижняя крышка:{' '}
                    <b>
                        {formulaStore.bottomCap
                            ? kAdditionInfoStore.capStr(formulaStore.bottomCap)
                            : 'Нет'}
                    </b>
                </Typography>
            );
        };
        const tail = () => {
            return (
                <Typography>
                    Нижний хвост: <b>{formulaStore.bottomTailLength / 100}м.</b>
                </Typography>
            );
        };

        return (
            <Stack>
                {rHorizontal()}
                <Stack direction="row" spacing={1}>
                    {cap()}
                    {tail()}
                </Stack>
            </Stack>
        );
    };

    const renderTableBody = () => {
        const style = (i) => {
            return formulaStore.isGapChange(i) ? { backgroundColor: '#A5DFA8' } : {};
        };
        const lengths = formulaStore.lengths;
        let depth = 0;
        let rows = [];
        for (let i = 0; i < lengths.length; i++) {
            rows.push(
                <TableRow key={i} hower sx={style(i)}>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>{(depth += lengths.at(i) / 100)}</TableCell>
                    <TableCell>{lengths.at(i) / 100}</TableCell>
                </TableRow>
            );
        }
        return rows;
    };

    const renderTable = () => {
        const table = () => {
            return (
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>№</StyledTableCell>
                            <StyledTableCell>Глубина</StyledTableCell>
                            <StyledTableCell>Расстояние м.</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow hover>
                            <TableCell>X</TableCell>
                            <TableCell>-</TableCell>
                            <TableCell>{formulaStore.tail_length / 100}</TableCell>
                        </TableRow>
                        {renderTableBody()}
                        {formulaStore.horizontal && (
                            <TableRow hover>
                                <TableCell>Н</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>{formulaStore.bottomTailLength / 100}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            );
        };

        return mobileView ? (
            table()
        ) : (
            <Paper sx={{ width: '100%', height: '60vh', overflow: 'hiden' }}>
                <TableContainer sx={{ maxHeight: '60vh' }}>{table()}</TableContainer>
            </Paper>
        );
    };

    if (formulaStore.loading === true) {
        return <LoadingStub item={item} onClose={onClose} />;
    }

    return (
        <Dialog
            open={item}
            onClose={onClose}
            disableRestoreFocus
            fullWidth
            maxWidth={mobileView ? false : 'md'}
            fullScreen={mobileView}>
            <StyledDialogTitle>Позиция заказа</StyledDialogTitle>
            <DialogContent>
                <Stack
                    direction={mobileView ? 'column' : 'row'}
                    spacing={2}
                    justifyContent="space-between">
                    <Stack spacing={1}>
                        <Typography>
                            Маркировка: <b>{formulaStore.marking_label}</b>
                        </Typography>
                        {renderSns()}
                        {renderLengthM()}
                        {formulaStore.text_formula === '' || (
                            <Typography>
                                Дополнительно: <b>{formulaStore.text_formula}</b>
                            </Typography>
                        )}
                        <Typography>
                            Тип косы:{' '}
                            <b>
                                {formulaStore.type_id < 0
                                    ? 'Без разницы'
                                    : kAdditionInfoStore.typeStr(formulaStore.type_id)}
                            </b>
                        </Typography>
                        {renderHorizontalBlock()}
                        <Stack direction="row" spacing={1}>
                            <Typography>
                                Грузик: <b>{formulaStore.dumbbell ? 'Есть' : 'Нет'}</b>
                            </Typography>
                            <Typography>
                                Крышка: <b>{kAdditionInfoStore.capStr(formulaStore.cap_type)}</b>
                            </Typography>
                        </Stack>
                        {(formulaStore.resistorOnTop || formulaStore.resistorOnBtn) && (
                            <Stack direction="row">
                                {formulaStore.resistorOnTop && (
                                    <Typography>
                                        Верхний резистор: <b>{formulaStore.resistorOnTop}</b>
                                    </Typography>
                                )}
                                {formulaStore.resistorOnBtn && (
                                    <Typography>
                                        Нижний резистор: <b>{formulaStore.resistorOnBtn}</b>
                                    </Typography>
                                )}
                            </Stack>
                        )}
                        {formulaStore.reel && (
                            <Typography>
                                Катушка: <b>{kAdditionInfoStore.reelStr(formulaStore.reel)}</b>
                            </Typography>
                        )}
                        <Typography>
                            Рабочая длина: <b>{formulaStore.fullLength() / 100}м.</b>
                        </Typography>
                    </Stack>

                    <Stack spacing={1}>
                        <Stack direction="row" justifyContent="flex-end">
                            <ViewTypeButton
                                mobile={mobileView}
                                onClick={() => setMobileView(!mobileView)}
                            />
                        </Stack>
                        {renderTable()}
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" color="primary">
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    );
});

//=================================================================================================
const Kosa = ({ item, onClose }) => {
    const { authStore } = useContext(AuthContext);

    const pushError = useErrorHandler();

    useEffect(() => {
        if (item) loadFormula();
    }, [item]);

    const loadFormula = async () => {
        try {
            await formulaStore.load(item);
        } catch (e) {
            pushError(e);
        }
    };

    const isAdmin = authStore.user.role === 2;

    return isAdmin ? (
        <AKosa item={item} onClose={onClose} />
    ) : (
        <UKosa item={item} onClose={onClose} />
    );
};

export default Kosa;
