import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';
import {
    Alert,
    Button,
    Divider,
    Link,
    FormControl,
    IconButton,
    InputLabel,
    Menu,
    MenuItem,
    Select,
    Snackbar,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableCell,
    TablePagination,
    TableFooter,
    TableHead,
    TableRow,
    Typography,
    TextField,
    Tooltip,
    Paper,
    Skeleton,
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import ClearAllIcon from '@mui/icons-material/ClearAll';

import { AdditionInfoContext } from '../ContentRoot/ContentRoot';
import { BuyerListContext } from '.';
import CreateBuyersDialog from '../Buyers/CreateBuyersDialog';
import RemoveDialog from './RemoveDialog';
import OrderService from '../../services/orderService';
import { OrderItemStore } from '../../store/orderItemStore';
import Loading from '../Loading';
import {
    BackButton,
    FileDropZone,
    StyledAutocomplete,
    StyledDataPicker,
    StyledTableCell,
    TextFieldWithState,
} from '../StyledComponents';
import rangeToString from '../../services/range-string';
import useErrorHandler from '../../services/error-handler';
import useMedia from '../../services/media-query';
import KosaService from '../../services/kosa-service';
import { SnsDialog } from '../KosaSns';
import SetOrderState, { STATES } from './SetOrderState';

const itemStore = new OrderItemStore();

//=================================================================================================
const OrderAdm = () => {
    const { isMobile } = useMedia();

    const { additionInformationStore } = useContext(AdditionInfoContext);
    const { buyerListStore } = useContext(BuyerListContext);

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const [loadingError, setLoadingError] = useState(''); //ошибка загрузки первоначальных данных страницы
    const [showRmDialog, setShowRmDialog] = useState(false); //диалог запроса удаления заказа
    const [itemMenuAnchor, setItemMenuAnchor] = useState(null); //меню выбора типа добавляемого пункта заказа
    const [order, setOrder] = useState(null);
    const [removeItemDialog, setRemoveItemDialog] = useState(null); //диалог запроса удаления позиции заказа
    const [showCreateBuyerDialog, setShowCreateBuyerDialog] = useState(false); //диалог запроса создания нового заказчика
    const [snsDialogForItem, setSnsDialogForItem] = useState(null); //отображение диалога выбора серийных номеров для пункта заказа (только коса)
    const [statusParams, setStatusParams] = useState(null); //параметры для процесса смены статусов заказов

    const pushError = useErrorHandler();

    const load = async () => {
        try {
            const order_id = params.id;
            await itemStore.setFilter({ order_id: order_id });
            const response = await OrderService.getOrder(order_id);
            setOrder(response.data);
        } catch (e) {
            pushError(e);
        }
    };

    const getInfo = async () => {
        try {
            await load();
        } catch (e) {
            console.log(e);
            pushError(new Error('Ошибка получения данных с сервера'));
        }
    };

    useEffect(() => {
        try {
            buyerListStore.load();
            load();
        } catch (e) {
            console.log(e);
            setLoadingError('Ошибка загрузки данных');
        }
    }, []);

    useEffect(() => {
        if (location.state?.refresh) {
            refreshData();
        }
    }, [location]);

    const refreshData = async () => {
        try {
            await itemStore.loadSize();
            await itemStore.loadItems();
        } catch (e) {
            pushError(e);
        }
    };

    if (!order && loadingError.length === 0) {
        return <Loading />;
    } else if (loadingError.length > 0) {
        return (
            <Snackbar open>
                <Alert severity="error">{loadingError}</Alert>
            </Snackbar>
        );
    }

    /**
     * выполнение запроса на сервер для обновления заказа, после сохранения посылает запрос на
     * обновление данных
     * @param {order} newOrder - данные заказа с измененными полями
     * @param {String | null} str - Имя измененного параметра заказа, для отображения в ошибке
     */
    const saveChange = async (newOrder, str = null) => {
        try {
            const response = await OrderService.update(newOrder);
            if (response.status !== 200 || response.data !== true) {
                if (str) throw new Error('Ошибка сохранения нового ' + str + ' заказа.');
                else throw new Error('Ошибка сохранения заказа.');
            } else {
                await getInfo();
            }
        } catch (e) {
            pushError(e);
        }
    };

    const onDeadlineDataChange = async (newDate) => {
        const newOrder = { ...order, deadline_time: newDate };
        saveChange(newOrder, 'дедлайна');
    };
    const onPriorityChange = async (event) => {
        const newOrder = { ...order, priority_id: event.target.value };
        saveChange(newOrder, 'приоритета');
    };

    const onStateChange = (event) => {
        setStatusParams({ state_id: event.target.value, order: order });
    };
    const onStateChanged = async () => {
        try {
            setStatusParams(null);
            await getInfo();
        } catch (e) {
            pushError(e);
        }
    };

    const onCommentChange = (event) => {
        const newOrder = { ...order, comment: event.target.value };
        saveChange(newOrder, 'комментария');
    };

    const onBuyerChange = (_, value) => {
        const newOrder = { ...order, buyer_id: value.buyer_id };
        saveChange(newOrder, 'заказчика');
    };

    const onBuyerEditClick = () => {
        navigate('/buyers/' + order.buyer_id);
    };

    const onNewBuyerCreated = (buyer) => {
        onBuyerChange(undefined, buyer);
        setShowCreateBuyerDialog(false);
    };

    const onDeleteButtonClick = () => {
        setShowRmDialog(true);
    };

    const onDeleteApply = async () => {
        try {
            await OrderService.remove(order);
            setShowRmDialog(false);
            navigate('/orders/');
        } catch (e) {
            pushError(e);
        }
    };

    const onAddItemClick = (event) => {
        navigate(`/formula/${event.target.value}/${order.order_id}`, {
            state: { backgroundLocation: location },
        });
        setItemMenuAnchor(null);
    };

    const snsDialogClose = async () => {
        try {
            setSnsDialogForItem(null);
            await itemStore.loadItems();
        } catch (e) {
            pushError(e);
        }
    };

    /**
     * Обновляет элемент в хранилище позиций заказа.
     * @param {Object} item - Элемент для обновления.
     * @throws {Error} Если произойдет ошибка при обновлении, выбрасывается ошибка.
     */
    const updateItem = (item) => {
        try {
            itemStore.update(item);
        } catch (e) {
            console.log(e);
            pushError(new Error('Ошибка обновления позиции в заказе'));
        }
    };

    const onItemDescriptionChange = (e, item) => {
        const newItem = { ...item, description: e.target.value };
        updateItem(newItem);
    };

    const onItemQuantityChange = (event, item) => {
        const newItem = { ...item, quantity: event.target.value };
        updateItem(newItem);
    };

    const onItemCommentChange = (e, item) => {
        const newItem = { ...item, comment: e.target.value };
        updateItem(newItem);
    };

    const onDeleteItemClick = (item) => {
        setRemoveItemDialog(item);
    };
    const onDeleteItemApply = async () => {
        await itemStore.delete(removeItemDialog);
        setRemoveItemDialog(null);
    };

    const onFileDrop = async (files) => {
        try {
            for (let i = 0; i < files.length; i++) {
                if (order.files.includes(files[i].name)) {
                    pushError(`Файл ${files[i].name} уже загружен`);
                    continue;
                }
                const fData = new FormData();
                fData.append('order_id', order.order_id);
                fData.append('file', files[i]);
                await OrderService.uploadFile(fData);
            }
            await getInfo();
        } catch (e) {
            pushError('Ошибка загрузки файла');
            console.log(e);
        }
    };

    const onFileRemove = async (file) => {
        try {
            await OrderService.deleteFile(order.order_id, file);
            await getInfo();
        } catch (e) {
            pushError('Ошибка удаления файла');
            console.log(e);
        }
    };

    const onDownloadFile = async (file) => {
        try {
            await OrderService.downloadFile(order.order_id, file);
        } catch (e) {
            pushError('Ошибка загрузки файла');
            console.log(e);
        }
    };
    const isEditable = () => {
        return order.state_id < 3; // "В обработке" или "В работе"
    };

    const autoAssignSnsClick = async (item) => {
        try {
            if (item.sns.length === 0) {
                const response = await KosaService.getNextId();
                let nextSns = +response.data;
                const nSns = [];
                for (let i = 0; i < item.quantity; i++) {
                    nSns.push(nextSns++);
                }
                await KosaService.save({ ...item, sns: nSns });
                await itemStore.loadItems();
            } else if (item.sns.length < item.quantity) {
                const nSns = [...item.sns];
                while (nSns.length < item.quantity) {
                    nSns.push(nSns.at(-1) + 1);
                }
                try {
                    await KosaService.save({ ...item, sns: nSns });
                } catch (e) {
                    throw new Error(
                        'Не удалось автоматически назначить серийные номера для позиции'
                    );
                }
                await itemStore.loadItems();
            } else if (item.sns.length > item.quantity) {
                const nSns = item.sns.slice(0, item.quantity);
                try {
                    await KosaService.save({ ...item, sns: nSns });
                } catch (e) {
                    throw new Error(
                        'Не удалось автоматически назначить серийные номера для позиции'
                    );
                }
                await itemStore.loadItems();
            }
        } catch (e) {
            pushError(e);
        }
    };

    //-------------------------------------------------------------------------------------------------
    //! Рендеринг меню приоритетов
    const renderPriorityMenu = () => {
        const priorities = additionInformationStore.priorities;
        const items = priorities.map((priority) => {
            return (
                <MenuItem value={priority.priority_id} key={priority.priority_id}>
                    {priority.state}
                </MenuItem>
            );
        });
        return items;
    };

    //! Рендеринг меню статусов
    const renderStateMenu = () => {
        const disabled = (state_id) => {
            if (order.state_id === STATES.PREPARED) return state_id > order.state_id + 2;
            else return state_id > order.state_id + 1;
        };
        const states = additionInformationStore.statuses;
        const items = states.map((status) => {
            return (
                <MenuItem
                    value={status.state_id}
                    key={status.state_id}
                    disabled={disabled(status.state_id)}>
                    {status.state}
                </MenuItem>
            );
        });
        return items;
    };

    const renderFileLinks = () => {
        return order.files.map((file) => (
            <div key={file}>
                <Tooltip title={'Скачать файл ' + file}>
                    <Link
                        component="button"
                        onClick={() => {
                            onDownloadFile(file);
                        }}>
                        {file}
                    </Link>
                </Tooltip>
                <Tooltip title={'Удалить файл ' + file}>
                    <IconButton
                        size="small"
                        onClick={() => {
                            onFileRemove(file);
                        }}>
                        <DeleteIcon color="error" size="small" />
                    </IconButton>
                </Tooltip>
            </div>
        ));
    };

    //! Рендер меню добавления пунктов в заказ
    const renderAddItemMenu = () => {
        const t = additionInformationStore.deviceTypes;
        const items = t.map((item) => {
            return (
                <MenuItem value={item.type_id} key={item.type_id} onClick={onAddItemClick}>
                    {item.name}
                </MenuItem>
            );
        });
        return items;
    };

    //-------------------------------------------------------------------------------------------------
    const renderOrderItems = () => {
        const isEditable = () => {
            return order.state_id === 1; //Если заказ в состоянии "В обработке" его можно редактировать
        };

        const renderWarningImage = (item) => {
            if ('correct_formula' in item && item.correct_formula === false) {
                return (
                    <Tooltip title="Возможно некорректная формула!">
                        <ErrorOutlineIcon
                            color="error"
                            sx={{ verticalAlign: 'bottom', marginRight: '0.4em' }}
                        />
                    </Tooltip>
                );
            }
        };

        const renderFormulaButton = (item) => {
            if ('formula' in item) {
                if (item.formula === true) {
                    return (
                        <Tooltip title="Редактировать формулу">
                            <IconButton
                                color="info"
                                onClick={() =>
                                    navigate(
                                        `/formula/${item.device_type}/${item.order_id}/${item.item_id}`,
                                        { state: { backgroundLocation: location } }
                                    )
                                }>
                                <ModeEditIcon />
                            </IconButton>
                        </Tooltip>
                    );
                }
            }
        };

        const renderSns = (item) => {
            if ('sns' in item) {
                const isRight = item.sns.length === item.quantity;
                const snStr = item.sns.length === 0 ? 'Не выбрано' : rangeToString(item.sns);
                return (
                    <Stack direction="row" alignItems="center" justifyContent="flex-end">
                        <Tooltip title="Присвоенные серийные номера">
                            <Typography sx={isRight ? null : { color: 'red' }} noWrap>
                                {isRight ? null : '<!> '}
                                {snStr}
                            </Typography>
                        </Tooltip>
                        <Tooltip title="Автоматическое присвоение серийных номеров">
                            <IconButton
                                color={isRight ? 'error' : 'info'}
                                disabled={!isEditable()}
                                onClick={() => autoAssignSnsClick(item)}>
                                <ClearAllIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Редактировать серийные номера">
                            <IconButton
                                color={isRight ? 'success' : 'error'}
                                disabled={!isEditable()}
                                onClick={() => setSnsDialogForItem(item)}>
                                <ViewCompactIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                );
            }
        };

        const items = itemStore.items;
        const result = items.map((item, index) => (
            <TableRow key={item.item_id} hover>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                    <Stack direction="row" alignItems="center">
                        {renderWarningImage(item)}
                        <TextFieldWithState
                            size="small"
                            fullWidth
                            value={item.description}
                            onChange={(e) => onItemDescriptionChange(e, item)}
                            disabled={!isEditable()}
                        />
                    </Stack>
                </TableCell>
                <TableCell>{additionInformationStore.deviceType(item.device_type)}</TableCell>
                <TableCell>
                    <TextFieldWithState
                        size="small"
                        fullWidth
                        value={item.quantity}
                        onChange={(e) => onItemQuantityChange(e, item)}
                        InputProps={{ inputProps: { min: 0, max: 10000, type: 'number' } }}
                        disabled={!isEditable()}
                    />
                </TableCell>
                <TableCell>
                    <TextFieldWithState
                        size="small"
                        fullWidth
                        value={item.comment}
                        onChange={(e) => onItemCommentChange(e, item)}
                        disabled={!isEditable()}
                    />
                </TableCell>
                <TableCell align="right">
                    {renderSns(item)}
                    <Stack direction="row" justifyContent="flex-end">
                        {renderFormulaButton(item)}
                        <Tooltip title="Удалить">
                            <IconButton
                                color="error"
                                onClick={() => onDeleteItemClick(item)}
                                disabled={!isEditable()}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </TableCell>
            </TableRow>
        ));
        return result;
    };

    const renderOrderItemsSkeleton = () => {
        const str = () => {
            return (
                <TableRow>
                    <TableCell>
                        <Skeleton />
                    </TableCell>
                    <TableCell>
                        <Skeleton variant="rounded" width={150} height={30} />
                    </TableCell>
                    <TableCell>
                        <Skeleton width={50} />
                    </TableCell>
                    <TableCell>
                        <Skeleton variant="rounded" width={120} height={30} />
                    </TableCell>
                    <TableCell>
                        <Skeleton variant="rounded" width={200} height={30} />
                    </TableCell>
                    <TableCell>
                        <Stack direction="row" justifyContent="flex-end" spacing={1}>
                            <Skeleton width={100} />
                            <Skeleton variant="circular" width={30} height={30} />
                            <Skeleton variant="circular" width={30} height={30} />
                        </Stack>
                    </TableCell>
                </TableRow>
            );
        };
        return new Array(itemStore.pageSize).fill(str());
    };

    //-------------------------------------------------------------------------------------------------
    return (
        <>
            <Stack spacing={2}>
                <Stack direction="row">
                    <BackButton />
                    <Typography variant="h5" sx={{ marginTop: '0.2em' }}>
                        Заказ № {order.number}
                    </Typography>
                </Stack>

                <div style={{ margin: '1em' }}>
                    <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                        useFlexGap
                        flexWrap={'wrap'}>
                        <StyledDataPicker
                            label="Дата создания"
                            fullWidth
                            value={DateTime.fromISO(order.create_time)}
                            disabled
                        />
                        <StyledDataPicker
                            label="Срок исполнения"
                            fullWidth
                            value={DateTime.fromISO(order.deadline_time)}
                            onChange={onDeadlineDataChange}
                            disabled={!isEditable()}
                        />
                        <FormControl>
                            <InputLabel id="priority-select">Приоритет</InputLabel>
                            <Select
                                label="Приоритет"
                                labelId="priority-select"
                                variant="outlined"
                                size="small"
                                value={order.priority_id}
                                onChange={onPriorityChange}
                                disabled={!isEditable()}
                                sx={{ minWidth: '10em' }}>
                                {renderPriorityMenu()}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <InputLabel id="status-select">Статус</InputLabel>
                            <Select
                                label="Статус"
                                labelId="status-select"
                                variant="outlined"
                                size="small"
                                value={order.state_id}
                                onChange={onStateChange}
                                sx={{ minWidth: '10em' }}>
                                {renderStateMenu()}
                            </Select>
                        </FormControl>
                        <Button
                            onClick={onDeleteButtonClick}
                            variant="contained"
                            color="secondary"
                            sx={isMobile ? { marginLeft: 'auto' } : {}}>
                            Удалить
                        </Button>
                    </Stack>

                    <Stack direction="row" spacing={0.5}>
                        <StyledAutocomplete
                            fullWidth
                            size="small"
                            value={{
                                name: buyerListStore.buyerName(order.buyer_id),
                                buyer_id: order.buyer_id,
                            }}
                            isOptionEqualToValue={(option, value) =>
                                option.buyer_id === value.buyer_id
                            }
                            onChange={onBuyerChange}
                            options={buyerListStore.buyers}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField {...params} label="Заказчик" margin="normal" />
                            )}
                            disabled={!isEditable()}
                        />
                        <Tooltip title="открыть страницу заказчика">
                            <IconButton onClick={onBuyerEditClick}>
                                <ModeEditIcon color="info" sx={{ paddingTop: '4px' }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Создать нового заказчика">
                            <IconButton
                                onClick={() => setShowCreateBuyerDialog(true)}
                                disabled={!isEditable()}>
                                <AddBoxIcon color="success" sx={{ paddingTop: '4px' }} />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    <TextFieldWithState
                        label="Дополнительно"
                        variant="outlined"
                        size="small"
                        fullWidth
                        multiline
                        value={order.comment}
                        onChange={onCommentChange}
                        disabled={!isEditable()}
                    />
                </div>
            </Stack>

            <Stack
                direction="row"
                spacing={1}
                useFlexGap
                sx={{
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginLeft: '1em',
                    flexWrap: 'wrap',
                }}
                divider={<Divider orientation="vertical" flexItem />}>
                {renderFileLinks()}
                <FileDropZone
                    mimeTypes={{ 'application/pdf': [] }}
                    maxSize={5 * 1024 * 1024}
                    onDrop={onFileDrop}
                />
            </Stack>

            <Button
                color="success"
                onClick={(e) => setItemMenuAnchor(e.currentTarget)}
                variant="contained"
                sx={{ margin: '1em' }}
                disabled={order.state_id > 1}>
                Добавить
                <ArrowDropDownIcon />
            </Button>
            <Menu
                anchorEl={itemMenuAnchor}
                open={Boolean(itemMenuAnchor)}
                onClose={() => setItemMenuAnchor(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                {renderAddItemMenu()}
            </Menu>

            <TableContainer component={Paper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>№</StyledTableCell>
                            <StyledTableCell>Название</StyledTableCell>
                            <StyledTableCell>Тип</StyledTableCell>
                            <StyledTableCell>Количество</StyledTableCell>
                            <StyledTableCell>Примечание</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {itemStore.loading ? renderOrderItemsSkeleton() : renderOrderItems()}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={6}>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20]}
                                    component="div"
                                    count={itemStore.allItemsSize}
                                    rowsPerPage={itemStore.pageSize}
                                    page={itemStore.page}
                                    onPageChange={(_, p) => itemStore.setPage(p)}
                                    onRowsPerPageChange={(e) =>
                                        itemStore.setPageSize(e.target.value)
                                    }
                                />
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>

            <CreateBuyersDialog
                open={showCreateBuyerDialog}
                onApply={onNewBuyerCreated}
                onClose={() => setShowCreateBuyerDialog(false)}
            />
            <RemoveDialog
                open={showRmDialog}
                title="Удалить?"
                text="Вы действительно хотите удалить этот заказ?"
                onClose={() => setShowRmDialog(false)}
                onApply={onDeleteApply}
            />
            <RemoveDialog
                open={Boolean(removeItemDialog)}
                title="Удалить?"
                text={
                    'Вы действительно хотите удалить пункт заказа с описанием ' +
                    removeItemDialog?.description
                }
                onApply={onDeleteItemApply}
                onClose={() => setRemoveItemDialog(null)}
            />
            <SnsDialog orderItem={snsDialogForItem} onClose={snsDialogClose} />
            <SetOrderState params={statusParams} onFinished={onStateChanged} />
        </>
    );
};

export default observer(OrderAdm);
