import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Tooltip, IconButton } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';

/**
 * Компонент для drag-and-drop загрузки файлов
 * @param {array<string>} mimeTypes - массив mime-types, которые могут быть загружены
 * @param {number} maxSize - максимальный размер файла в байтах
 * @param {function} onDrop - функция, которая будет вызвана, когда файлы будут загружены
 * @returns React-компонент, который может быть использован в render
 */
const FileDropZone = ({ mimeTypes, maxSize, onDrop }) => {
    const onTDrop = useCallback((acceptedFiles) => {
        onDrop(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: onTDrop,
        multiple: true,
        accept: mimeTypes,
        maxSize: maxSize,
    });

    return (
        <Box
            {...getRootProps()}
            sx={{
                border: '2px dashed',
                borderColor: isDragActive ? 'primary.main' : 'grey.500',
                borderRadius: 2,
                padding: 0.2,
                textAlign: 'center',
                transition: 'border-color 0.3s',
                cursor: 'pointer',
                backgroundColor: isDragActive ? 'grey.100' : 'transparent',
            }}>
            <input {...getInputProps()} />
            <Tooltip title="Добавить файл">
                <IconButton>
                    <AddBoxIcon color="success" />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

export default FileDropZone;
